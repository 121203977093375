<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitSelection"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<spot-button
					:ref="fields[field.withdraw].name"
					:text="fields[field.withdraw].text"
					:enabled="itemsSelected.length>0"
					:size="fields[field.withdraw].size"
					:fill="fields[field.withdraw].fill"
					:icon="fields[field.withdraw].icon"
					:expand="fields[field.withdraw].expand"
					:color="fields[field.withdraw].color"
					@clicked="selectedExistingProjects">
				</spot-button>
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						:canRefresh="fields[field.search].canRefresh"
						@refresh="$router.push('/picking/selection')"
						@textChanged="changedSearch">
					</spot-input>
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.projects"></ion-progress-bar> <!-- v-if="!tablesData.projectRows" -->
				<EasyDataTable
					v-model:items-selected="itemsSelected"
					:headers="headers"
					:items="tablesData.projects"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					:body-row-class-name="bodyRowClassNameFunction"
					multi-sort> <!-- show-index class="disabled" alternating -->
					<template #item-actions="project">
						<ion-button disabled=false color="dark" fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProject(project)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
				</EasyDataTable>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in projectContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number') && field.visible && !!field.value"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area' && field.visible && !!field.value"
								:ref="field.name"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:disabled="!field.enabled || modalReadOnly"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="(field.inputType == 'check') && field.visible"
								:ref="field.name"
								:label="field.text"
								:allignLabel="field.allignLabel"
								:required="field.required"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)">
							</spot-checkbox>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="small"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

	</ion-page>
</template>

<style scoped>
 .disabled {
  pointer-events: none;
  opacity: 0.7;
 }
 .flex-row-center-container {
  margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
</style>
<style>
	.closed-row {
		--easy-table-body-row-background-color: var(--ion-color-danger);
	}
	.partial-row {
		--easy-table-body-row-background-color: var(--ion-color-warning);
	}
	.new-row {
		/* --easy-table-body-row-background-color: var(--ion-color-success);*/
		--easy-table-body-row-background-color: var(--ion-color-light);
	}
	.suspended-row {
		--easy-table-body-row-background-color: var(--ion-color-tertiary);
	}
	.expanded-row {
		--easy-table-body-row-background-color: var(--ion-color-medium);
	}
	.prominence-column {
		font-size: 20px;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonModal, IonPage, IonProgressBar, IonTextarea, IonTitle, IonToolbar } from '@ionic/vue';
import { reader } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { /* checkUserSession, confirmYesNo, insertValue, */settings, showMsgError/*, showMsgInfo*/, showMsgWarning, writeLog } from '../plugins/common.js';
import { createProject, searchProjectTypology, searchProjects, tablesData, updateProject } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectPickingLists",
		props: {
			pageTitle: { type: String, default: 'Seleziona Liste di Prelievo' },
			pickingListTitle: { type: String, default: 'LP' }, //placeholder.picking_list
			noItemsText: { type: String, default: 'Nessuna Lista di Prelievo presente' },
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			showPickingList: { type: String, default: 'Visualizza Lista di Prelievo' },
			messageSearchPickingListTitle: { type: String, default: 'Ricerca Lista di Prelievo' },
			messageSearchPickingListNotFound: { type: String, default: 'Lista di Prelievo non trovata!' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonModal,
			IonTextarea,
			IonTitle,
			IonToolbar,
			SpotHeader,
			SpotInput,
			SpotCheckbox,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				withdraw: 0,
				search: 1,
			},
			fields: [
				{ id: 0, name: 'withdraw', text: 'Gestisci selezione', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'listCircle', color: 'primary' },
				{ id: 1, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Collo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null, canRefresh: true  },
			],
			searchFields: ['name', 'customer.name'],
			headers: [
				{ text: 'Numero', value: 'name', sortable: true },
				{ text: 'Cliente', value: 'customer.name', sortable: true },
				{ text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'asc', 'asc', 'desc', 'desc', 'desc'],
			sortBy: ['closed', 'prepared', 'delivered', 'partial', 'suspended', 'updated_at'/*'name'*/],
			projectContent: {
				id: 0,
				name: 1,
				description: 2,
				projectDate: 3,
				shippingDate: 4,
				customer: 5,
				customerInfo: 6,
				supplier: 7,
				supplierInfo: 8,
				address: 9,
				addressInfo: 10,
				agent: 11,
				packages: 12,
				operator: 13,
				payment: 14,
				plant: 15,
				closed: 16,
				partial: 17,
				prepared: 18,
				delivered: 19,
				suspended: 20,
			},
			projectContents: [
				{ id: 0, name: 'id', text: 'Id', placeholder: 'Inserisci ID', inputType: "number", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 1, name: 'name', text: 'Nr. Lista', placeholder: 'Inserisci Nr. Lista', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 2, name: 'description', text: 'Note', placeholder: 'Inserisci Note', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 3, name: 'projectDate', text: 'Data Progetto', placeholder: 'Inserisci Data Progetto', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 4, name: 'shippingDate', text: 'Data Prevista', placeholder: 'Inserisci Data Prevista', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 5, name: 'customer', text: 'Cliente', placeholder: 'Inserisci Cliente', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 6, name: 'customerInfo', text: 'Dettagli Cliente', placeholder: 'Inserisci Dettagli Cliente', inputType: "area", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 7, name: 'supplier', text: 'Fornitore', placeholder: 'Inserisci Fornitore', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 8, name: 'supplierInfo', text: 'Dettagli Fornitore', placeholder: 'Inserisci Dettagli Fornitore', inputType: "area", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 9, name: 'address', text: 'Destinazione', placeholder: 'Inserisci Destinazione', inputType: "text", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 10, name: 'addressInfo', text: 'Dettagli Destinazione', placeholder: 'Inserisci Dettagli Destinazione', inputType: "area", enabled: false, visible: true, allignLabel: false, validationState: null, defaultValue: null, value: null, required: false },
				{ id: 11, name: 'agent', text: 'Consegna Agente', inputType: "check", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 12, name: 'packages', text: 'Nr. Colli', placeholder: 'Inserisci Nr. Colli', inputType: "number", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 13, name: 'operator', text: 'Operatore', placeholder: 'Inserisci Operatore', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 14, name: 'payment', text: 'Codice Pagamento', placeholder: 'Inserisci Codice Pagamento', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 15, name: 'plant', text: 'Zona', placeholder: 'Inserisci Zona', inputType: "text", enabled: false, visible: true, allignLabel: true, validationState: null, defaultValue: null, value: null, required: true },
				{ id: 16, name: 'closed', text: 'Chiuso', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 17, name: 'partial', text: 'Parziale', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 18, name: 'prepared', text: 'Preparato', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 19, name: 'delivered', text: 'Spedito', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
				{ id: 20, name: 'suspended', text: 'Sospeso', inputType: "check", enabled: false, visible: false, allignLabel: true, validationState: null, defaultValue: false, value: null, required: false },
			],
			isOpenModal: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			lastBarcode: null,
			lastTag: null,
			reader,
			settings,
			// checkUserSession,
			// confirmYesNo,
			showMsgError,
			// showMsgInfo,
			showMsgWarning,
			writeLog,
			searchProjectTypology,
			searchProjects,
			createProject,
			updateProject,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.itemsSelected = [];
			this.isOpenModal = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.projectContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
		},
		setup() {
			const itemsSelected = ref([]);
			const bodyRowClassNameFunction = (item) => {
				if (item.suspended == true) return 'suspended-row'
				else return 'new-row';
			}
			return {
				itemsSelected,
				bodyRowClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				// Nothing to do
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData() {
				this.tablesData.projects = [];
				this.tablesData.currentProjectTypology = null;
				await this.searchProjectTypology(this.pickingListTitle);
				await this.searchProjects(this.tablesData.currentProjectTypology.id, false, false, false, false, null/*false*/, null/*false*/);
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.projectContents[fieldId].value = value;
				(!value || value.length == 0)? this.projectContents[fieldId].validationState = false : this.projectContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.projectContents[fieldId].value = value;
				(!value || value.length == 0)? this.projectContents[fieldId].validationState = false : this.projectContents[fieldId].validationState = true
			},
			prepareNewProject() {
				let myRecord = {
					project: {
						name: this.projectContents[this.projectContent.name].value,
						description: this.projectContents[this.projectContent.description].value,
						customer: this.projectContents[this.projectContent.customer].value,
						supplier: this.projectContents[this.projectContent.supplier].value,
						closed: this.projectContents[this.projectContent.closed].value,
						partial: this.projectContents[this.projectContent.partial].value,
						prepared: this.projectContents[this.projectContent.prepared].value,
						delivered: this.projectContents[this.projectContent.delivered].value,
					}
				};
				return myRecord;
			},
			prepareUpdateProject() {
				let myRecord = {
					project: {
						name: this.projectContents[this.projectContent.name].value,
						description: this.projectContents[this.projectContent.description].value,
					}
				};
				return myRecord;
			},
			updateCustomProjectContents(row) {
				this.projectContents[this.projectContent.id].value = row.id
				this.projectContents[this.projectContent.name].value = row.name
				this.projectContents[this.projectContent.description].value = row.description
				this.projectContents[this.projectContent.projectDate].value = (!row.project_date ? null : new Date(row.project_date).toLocaleDateString())
				this.projectContents[this.projectContent.shippingDate].value = (!row.estimated_shipping_date ? null : new Date(row.estimated_shipping_date).toLocaleDateString())
				this.projectContents[this.projectContent.customer].value = (!row.customer ? null : row.customer.name)
				this.projectContents[this.projectContent.customerInfo].value = (!row.customer ? null : (row.customer.address + "\n" + row.customer.postal_code + " " + row.customer.locality + " (" + row.customer.province + ")"))
				this.projectContents[this.projectContent.supplier].value = (!row.supplier ? null : row.supplier.name)
				this.projectContents[this.projectContent.supplierInfo].value = (!row.supplier ? null : (row.supplier.address + "\n" + row.supplier.postal_code + " " + row.supplier.locality + " (" + row.supplier.province + ")"))
				this.projectContents[this.projectContent.address].value = (!row.address ? null : row.address.name)
				this.projectContents[this.projectContent.addressInfo].value = (!row.address ? null : (row.address.address + "\n" + row.address.postal_code + " " + row.address.locality + " (" + row.address.province + ")"))
				this.projectContents[this.projectContent.agent].value = (!row.transport_mean ? null : row.transport_mean.flag_agent)
				this.projectContents[this.projectContent.payment].value = row.payment_code
				this.projectContents[this.projectContent.plant].value = (!row.plant ? null : row.plant.name)
				this.projectContents[this.projectContent.packages].value = row.package_number
				this.projectContents[this.projectContent.operator].value = row.operator
				this.projectContents[this.projectContent.closed].value = row.closed
				this.projectContents[this.projectContent.partial].value = row.partial
				this.projectContents[this.projectContent.prepared].value = row.prepared
				this.projectContents[this.projectContent.delivered].value = row.delivered
				this.projectContents[this.projectContent.suspended].value = row.suspended
			},
			async showExistingProject(project) {
				this.updateCustomProjectContents(project);
				this.projectContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showPickingList;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async selectedExistingProjects() {
				let projectIds = null;
				if (!this.isOpenModal) {
					for(const element of this.itemsSelected) {
						if (!projectIds) projectIds = element.id
						else projectIds += "," + element.id
					}
					this.$router.push("/picking/management" + "?projectIds=" + projectIds)
				}
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						await this.createProject(this.prepareNewProject())
					}
					else {
						await this.updateProject(this.projectContents[this.projectContent.id].value, this.prepareUpdateProject())
					}
				}
				await this.getAllTablesData();
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.projects.some(element => {
					if (element.name == this.lastBarcode) {
						foundElement = element;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchPickingListTitle, this.messageSearchPickingListNotFound);
				else if (!this.itemsSelected.includes(foundElement)) this.itemsSelected.push(foundElement);
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.projects.some(element => {
							if (element.name == this.lastTag) {
								foundElement = element;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchPickingListTitle, this.messageSearchPickingListNotFound);
      else if (!this.itemsSelected.includes(foundElement)) this.itemsSelected.push(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			async exitSelection() {
				let canExit = !this.isOpenModal;
				
				if (canExit == true) {
					this.$router.push("/home");
				}
			}
		}
	});
</script>
