<template>
	<ion-page>
		<spot-header :title="feName" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitIncoming"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<spot-select
					:ref="locationField.name"
					:label="locationField.text"
					:allignLabel="locationField.allignLabel"
					:required="locationField.required"
					:placeholder="getPlaceHolder(locationField)"
					:enabled="!tablesData.location"
					:value="locationField.value"
					:options="tablesData.locations"
					:optionField="locationField.refField"
					:validationState="locationField.validationState"
					:canAddItem="locationField.canAddItem"
					:canRefreshList="locationField.canRefresh"
					:canUnlock="locationField.canUnlock"
					@selected="selectedLocation"
					@unselected="unselectedLocation"
					@refresh="refreshedLocation">
				</spot-select>
				<spot-input v-if="showReadersFields"
					:ref="barcodeField.name"
					:label="barcodeField.text"
					:allignLabel="barcodeField.allignLabel"
					:required="barcodeField.required"
					:placeholder="getPlaceHolder(barcodeField)"
					:enabled="false"
					:value="barcodeField.value"
					:furtherInfo="barcodeField.furtherInfo"
					:type="barcodeField.inputType"
					:clearInput="false"
					:validationState="barcodeField.validationState"
					:canUnlock="barcodeField.canUnlock"
					@textChanged="changedBarcode"
					@committed="committedBarcode">
				</spot-input>
				<spot-select
					:ref="projectField.name"
					:label="projectField.text"
					:allignLabel="projectField.allignLabel"
					:required="projectField.required"
					:placeholder="getPlaceHolder(projectField)"
					:enabled="true"
					:value="projectField.value"
					:options="tablesData.projects"
					:optionField="projectField.refField"
					:validationState="projectField.validationState"
					:canAddItem="projectField.canAddItem"
					:canRefreshList="projectField.canRefresh"
					:canUnlock="projectField.canUnlock"
					@selected="selectedProject"
					@unselected="unselectedProject"
					@refresh="refreshedProject">
				</spot-select>
				<spot-select
					:ref="itemField.name"
					:label="itemField.text"
					:allignLabel="itemField.allignLabel"
					:required="itemField.required"
					:placeholder="getPlaceHolder(itemField)"
					:enabled="!!projectField.value"
					:value="itemField.value"
					:options="tablesData.items"
					:optionField="itemField.refField"
					:validationState="itemField.validationState"
					:canAddItem="itemField.canAddItem"
					:canRefreshList="itemField.canRefresh"
					:canUnlock="itemField.canUnlock"
					@selected="selectedItem"
					@unselected="unselectedItem"
					@refresh="refreshedItem">
				</spot-select>
				<spot-input v-if="showItemDescription"
					:ref="descriptionField.name"
					:label="descriptionField.text"
					:allignLabel="descriptionField.allignLabel"
					:required="descriptionField.required"
					:placeholder="getPlaceHolder(descriptionField)"
					:enabled="false"
					:value="descriptionField.value"
					:furtherInfo="descriptionField.furtherInfo"
					:type="descriptionField.inputType"
					:clearInput="false"
					:validationState="descriptionField.validationState"
					:canUnlock="descriptionField.canUnlock"
					@textChanged="changedDescription">
				</spot-input>
				<spot-input
					:ref="serialField.name"
					:label="serialField.text"
					:allignLabel="serialField.allignLabel"
					:required="serialField.required"
					:placeholder="getPlaceHolder(serialField)"
					:enabled="true"
					:value="serialField.value"
					:furtherInfo="serialField.furtherInfo"
					:type="serialField.inputType"
					:clearInput="true"
					:validationState="serialField.validationState"
					:canUnlock="serialField.canUnlock"
					@textChanged="changedSerial"
					@committed="committedSerial">
				</spot-input>
				<spot-input
					:ref="quantityField.name"
					:label="quantityField.text"
					:allignLabel="quantityField.allignLabel"
					:required="quantityField.required"
					:placeholder="getPlaceHolder(quantityField)"
					:enabled="true"
					:value="quantityField.value"
					:furtherInfo="quantityField.furtherInfo"
					:type="quantityField.inputType"
					:clearInput="true"
					:validationState="quantityField.validationState"
					:canUnlock="quantityField.canUnlock"
					@textChanged="changedQuantity"
					@committed="committedQuantity">
				</spot-input>
				<spot-input v-if="showReadersFields"
					:ref="tagField.name"
					:label="tagField.text"
					:allignLabel="tagField.allignLabel"
					:required="tagField.required"
					:placeholder="getPlaceHolder(tagField)"
					:enabled="true"
					:value="tagField.value"
					:furtherInfo="tagField.furtherInfo"
					:type="tagField.inputType"
					:clearInput="true"
					:validationState="tagField.validationState"
					:canUnlock="tagField.canUnlock"
					@textChanged="changedTag"
					@committed="committedTag">
				</spot-input>
				<spot-button
					:ref="confirmButton.name"
					:text="confirmButton.text"
					:enabled="!!(locationField.value && projectField.value && itemField.value && serialField.value && quantityField.value && tagField.value)"
					:size="confirmButton.size"
					:fill="confirmButton.fill"
					:icon="confirmButton.icon"
					:expand="confirmButton.expand"
					:color="confirmButton.color"
					@clicked="save">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

	</ion-page>
</template>

<style scoped>
	ion-content {
		display: flex;
		flex-direction: column;
	}
	ion-content *:deep(ion-label) {
		min-width: 25vw !important;
		max-width: 25vw !important;
	}
	/*
	ion-content *:deep(*) {
		--padding-bottom:	0px;
		--padding-top:	0px;
		font-size: min(3vw,3vh);
	}
	*/
</style>

<script>
import { feName } from '@/plugins/variables.js';
import { IonContent, IonPage, IonProgressBar } from '@ionic/vue';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotHeader from '../components/SpotHeader.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotSelect from '../components/SpotSelect.vue';
import WebSocketReader from '../components/WebSocketReader.vue';
import { checkUserSession, confirmYesNo /*, insertValue*/, getUserInfo, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';

	export default defineComponent({
		name: 'IncomingItems',
		props: {
			// pageTitle: { type: String, default: 'Ingresso Merce' },
			typologyTitle: { type: String, default: 'Ingresso Merce Con Lista' },
			messageTitleSave: { type: String, default: 'Salvataggio Dati' },
			messageTitleSearch: { type: String, default: 'Ricerca Elemento' },
			messageTitleCheckBarcode: { type: String, default: 'Controllo Barcode' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			confirmQuantityTitle: { type: String, default: 'Conferma Peso' },
			confirmQuantityMessage: { type: String, default: 'Confermi l\'inserimento di un peso superiore a quello previsto?' },
			successSaveMessage: { type: String, default: 'Articolo inserito correttamente!' },
			errorSaveProjectRow: { type: String, default: 'Errore durante il salvataggio del TAG' },
			errorUpdateProjectRow: { type: String, default: 'Errore durante l\'aggironamento del TAG' },
			errorSaveMessage: { type: String, default: 'Errore durante il salvataggio del movimento di ingresso merce' },
			warnBarcodeDoesntMatchOrder: { type: String, default: 'Il barcode letto non contiene un ordine aperto' },
			warnBarcodeHasNotOrder: { type: String, default: 'Il barcode letto non contiene il campo ordine' },
			warnItemDoesntMatchOrder: { type: String, default: 'L\'articolo associato al barcode non è presente nell\'ordine' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			warnTagExist: { type: String, default: ' già inserito.' + '<br>' + 'Controllare prima di fare l\'assegnazione!' },
			warnTagReassign: { type: String, default: ' già inserito.' + '<br>' + 'Confermi l\'assegnazione del tag a questo ordine?' },
			warnSaveSerial: { type: String, default: 'Nuovo Lotto non creato!' },
			errorSearchLocation: { type: String, default: 'Errore nella ricerca delle Ubicationi' },
			errorSearchProjectRows: { type: String, default: 'Errore nella ricerca delle Righe di Movimento' },
			errorSearchPicksAndItems: { type: String, default: 'Errore in ricerca Righe Ordine e Articoli' },
			errorSearchSerial: { type: String, default: 'Errore nella ricerca dei Lotti' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			errorSearchTag: { type: String, default: 'TAG già inserito!' },
			barcodeAiProject: { type: String, default: 'ente : ' },
			barcodeAiItem: { type: String, default: 'Articolo Cliente : ' },
			barcodeAiSerial: { type: String, default: 'Lotto : ' },
			barcodeAiQuantity: { type: String, default: 'Peso netto Kg : ' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			SpotHeader,
			SpotSelect,
			SpotInput,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			isOpenModal: false,
			showCaptions: true,
			showReadersFields: true,
			showItemDescription: true,
			fields: [
				{ id: 0, name: 'location', text: 'Ingresso', allignLabel: true, placeholder: 'Seleziona Ubicazione', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: true },
				{ id: 1, name: 'barcode', text: 'Barcode', allignLabel: true, placeholder: 'Leggere Barcode', emptyValue: null, inputType: 'text', defaultValue: null, value: null, required: false, furtherInfo: null, validationState: null, canUnlock: false },
				{ id: 2, name: 'project', text: 'Ordine', allignLabel: true, placeholder: 'Seleziona Ordine', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: true, canUnlock: false },
				{ id: 3, name: 'item', text: 'Articolo', allignLabel: true, placeholder: 'Seleziona Articolo', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'code', validationState: null, canAddItem: false, canRefresh: true, canUnlock: false },
				{ id: 4, name: 'description', text: null/*'Descrizione'*/, allignLabel: true, placeholder: null, emptyValue: null, inputType: 'text', defaultValue: null, value: null, required: false, furtherInfo: null, validationState: null, canUnlock: false },
				{ id: 5, name: 'lot', text: 'Lotto', allignLabel: true, placeholder: 'Inserire Lotto', emptyValue: null, inputType: 'text', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canUnlock: false },
				{ id: 6, name: 'weight', text: 'Peso', allignLabel: true, placeholder: 'Inserire Peso', emptyValue: null, inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canUnlock: false },
				{ id: 7, name: 'tag', text: 'RfId', allignLabel: true, placeholder: 'Leggere TAG', emptyValue: null, inputType: 'text', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canUnlock: false },
				{ id: 8, name: 'confirm', text: 'Salva', inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'save', color: 'primary' },
			],
			tablesData: {
				locations: [],
				projects: [],
				project_rows: [],
				picks: [],
				items: [],
				project_typology: null,
				location: null,
				project_row: null,
				serial: null,
				tag: null,
			},
			feName,
			settings,
			checkUserSession,
			confirmYesNo,
			getUserInfo,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
			writeLog,
		}),
		computed: {
			locationField() { return this.fields[0] },
			barcodeField() { return this.fields[1] },
			projectField() { return this.fields[2] },
			itemField() { return this.fields[3] },
			descriptionField() { return this.fields[4] },
			serialField() { return this.fields[5] },
			quantityField() { return this.fields[6] },
			tagField() { return this.fields[7] },
			confirmButton() { return this.fields[8] },
		},
		async ionViewWillEnter() {
			await this.setDefault();
			await this.getAllTablesData();
			await this.assignLocation();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			// Settings & StartUp
			getPlaceHolder(inputElement) {
				//return ((this.settings.showFieldsPlaceholder) ? (inputElement.required ? inputElement.placeholder : inputElement.emptyValue ) : '')
				return ((this.settings.showFieldsPlaceholder) ? inputElement.placeholder : '')
			},
			// Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch('tables/querySearch', { model: tableName, data: filters })
				return data;
			},
			async getData(tableName) {
				let data = await this.$store.dispatch('tables/fetch', { model: tableName })
				return data;
			},
			async updateData(tableName, recordId, record) {
				let data = await this.$store.dispatch('tables/update', { model: tableName, id: recordId, data: record })
				return data;
			},
			async writeData(tableName, record) {
				let data = await this.$store.dispatch('tables/create', { model: tableName, data: record })
				return data;
			},
			async getAllTablesData() {
				await this.getLocations();
				await this.searchProjectTypology();
				await this.customSearchProjects();
			},
			async getLocations() {
				await this.getData('locations')
				.then(data => { this.tablesData.locations = data; })
				.catch(error => { this.tablesData.locations = []; this.checkUserSession(error, 'warning'); });
			},
			async searchProjectTypology() {
				let mySearch = {
					params: {
						'q[title_eq]': this.typologyTitle,
					}
				};
				await this.searchData('project_typologies', mySearch)
				.then(data => { if (data.length>0) { this.tablesData.project_typology = data[0]; } })
				.catch(error => { this.checkUserSession(error, 'warning'); })
			},
			async customSearchProjects() {
				let mySearch = {
					params: {
						'q[project_typology_id_eq]': this.tablesData.project_typology.id,
						'q[closed_eq]': false,
					}
				};
				await this.searchData('projects', mySearch)
				.then(data => { this.tablesData.projects = data; })
				.catch(error => { this.tablesData.projects = []; this.checkUserSession(error, 'warning'); });
			},
			async searchLocation(locationId) {
				let mySearch = {
					params: {
						'q[id_eq]': locationId,
					}
				};
				await this.searchData('locations', mySearch)
				.then(data => {
					if (data.length == 1) {
						this.locationField.value = data[0];
					}
				})
				.catch(error => {
					this.checkUserSession(error, 'warning');
					this.showMsgError(this.messageTitleSearch, this.errorSearchLocation);
				})
			},
			async searchProjectRows() {
				let mySearch = {
					params: {
						'q[project_id_eq]': this.projectField.value.id,
					}
				};
				this.tablesData.project_rows = [];
				await this.searchData('project_rows', mySearch)
				.then(data => {
					this.tablesData.project_rows = data;
				})
				.catch(error => {
					this.checkUserSession(error, 'warning');
					this.showMsgError(this.messageTitleSearch, this.errorSearchProjectRows);
				})
			},
			async searchPicksAndItems() {
				let mySearch = {
					params: {
						'q[project_id_eq]': this.projectField.value.id,
					}
				};
				this.tablesData.picks = [];
				this.tablesData.items = [];
				await this.searchData('picks', mySearch)
				.then(data => {
					this.tablesData.picks = data;
					data.forEach(element => {
						this.tablesData.items.push(element.item);
					});
				})
				.catch(error => {
					this.checkUserSession(error, 'warning');
					this.showMsgError(this.messageTitleSearch, this.errorSearchPicksAndItems);
				})
			},
			async searchSerial() {
				this.tablesData.serial = null;
				let mySearch = {
					params: {
						'q[code_eq]': this.serialField.value,
						'q[item_id_eq]': this.itemField.value.id,
					}
				};
				await this.searchData('serials', mySearch)
				.then(data => {
					if (data.length>0) this.tablesData.serial = data[0];
				})
				.catch(error => {
					this.checkUserSession(error, 'warning');
					this.showMsgError(this.messageTitleSearch, this.errorSearchSerial);
				})
			},
			async searchTag() {
				this.tablesData.tag = null;
				this.tablesData.project_row = null;
				let mySearch = {
					params: {
						'q[code_eq]': this.tagField.value,
					}
				};
				await this.searchData('project_rows', mySearch)
				.then(data => { if (data.length>0) { this.tablesData.tag = data[0].code; this.tablesData.project_row = data[0]; } })
				.catch(error => { this.checkUserSession(error, 'warning'); this.showMsgError(this.messageTitleSearch, this.errorSearchTag); })
			},
			async checkOrder() {
				if (this.projectField.value) {
					await this.searchProjectRows();
					await this.searchPicksAndItems();
					return true;
				}
				else {
					this.clearOrderFields();
					this.showMsgWarning(this.messageTitleCheckBarcode,this.warnBarcodeDoesntMatchOrder);
					return false;
				}
			},
			async checkItem(item) {
				for (let i = 0, l = this.tablesData.items.length; i < l; i++) {
					if (this.tablesData.items[i].code == item) {
						this.itemField.value = this.tablesData.items[i];
						this.descriptionField.value = this.tablesData.items[i].description;
						this.quantityField.furtherInfo = '/ ' + (this.tablesData.picks[i].quantity - this.tablesData.picks[i].quantity_detected);
					}
				}
				if (!this.itemField.value) {
					this.clearOrderFields(false);
					this.showMsgWarning(this.messageTitleCheckBarcode,this.warnItemDoesntMatchOrder);
				}
			},
			prepareSerial() {
				let myRecord = {
					serial: {
						item_id: this.itemField.value.id,
						code: this.serialField.value,
					}
				};
				return myRecord;
			},
			prepareProjectRow(serialId) {
				let myRecord = {
					project_row: {
						project_id: this.projectField.value.id,
						location_id: this.locationField.value.id,
						item_id: this.itemField.value.id,
						description: this.itemField.value.description,
						serial_id: serialId,
						quantity: this.quantityField.value,
						code: this.tagField.value,
					}
				};
				return myRecord;
			},
			prepareTrack(projectRowId) {
				let myRecord = {
					track: {
						project_row_id: projectRowId,
						location_id: this.locationField.value.id,
						last_seen_at: new Date().toISOString(),
					}
				};
				return myRecord;
			},
			async save() {
				var canSave = true
				var projectRowId = null;
				if (Number(this.quantityField.value) > Number(this.quantityField.furtherInfo.substring(2))) {
					await this.confirmYesNo(this.confirmQuantityTitle, this.confirmQuantityMessage)
					.then((data) => { canSave = data })
				}
				if (canSave == true) {
					await this.searchSerial();
					if (this.tablesData.serial == null) {
						await this.writeData('serials', this.prepareSerial())
						.then(data => {
						this.tablesData.serial = data;
						})
						.catch(error => {
							this.checkUserSession(error, 'warning');
							this.showMsgError(this.messageTitleSave, this.errorSaveMessage);
						})
					}
					if (this.tablesData.serial != null) {
						await this.searchTag()
						if (this.tablesData.tag == null) {
							await this.writeData('project_rows', this.prepareProjectRow(this.tablesData.serial.id))
							.then(data => {
								if (data !=null) projectRowId = data.id;
							})
							.catch(error => {
								this.checkUserSession(error, 'warning');
								this.showMsgError(this.messageTitleSave, this.errorSaveProjectRow);
							})
						}
						else {
							// this.showMsgWarning(this.messageTitleSave,'TAG ' + this.tablesData.tag + this.warnTagExist);
							await this.confirmYesNo(this.messageTitleSave, 'TAG ' + this.tablesData.tag + this.warnTagReassign)
							.then((data) => { canSave = data })
							if (canSave == true) {
								await this.updateData('project_rows', this.tablesData.project_row.id, this.prepareProjectRow(this.tablesData.serial.id))
								.then(data => {
									if (data !=null) projectRowId = data.id;
								})
								.catch(error => {
									this.checkUserSession(error, 'warning');
									this.showMsgError(this.messageTitleSave, this.errorUpdateProjectRow);
								})
							}
						}
					}
					else {
						this.showMsgWarning(this.messageTitleSave, this.warnSaveSerial);
					}
					if (projectRowId != null) {
						await this.writeData('tracks', this.prepareTrack(projectRowId))
						.then(() => {
							this.showMsgInfo(this.messageTitleSave, this.successSaveMessage);
							this.clearAllFields();
							this.refreshedProject();
						})
						.catch(error => {
							this.checkUserSession(error, 'warning');
							this.showMsgError(this.messageTitleSave, this.errorSaveMessage);
						})
					}
				}
			},
			// Actions
			async setDefault() {
				this.tablesData.location = await this.getUserInfo().location_id;
				this.hideFieldsCaption();
			},
			hideFieldsCaption() {
				if (!this.showCaptions) {
					this.locationField.text = null;
					this.barcodeField.text = null;
					this.projectField.text = null;
					this.itemField.text = null;
					this.descriptionField.text = null;
					this.serialField.text = null;
					this.quantityField.text = null;
					this.tagField.text = null;
				}
			},
			clearAllFields(clearOrderField = true) {
				this.clearOrderFields(clearOrderField);
				this.clearInputFields();
			},
			clearOrderFields(clearOrderField = true) {
				if (clearOrderField) this.projectField.value = this.projectField.defaultValue;
				this.itemField.value = this.itemField.defaultValue;
				this.descriptionField.value = this.descriptionField.defaultValue;
				this.serialField.value = this.serialField.defaultValue;
				this.quantityField.value = this.quantityField.defaultValue;
				this.quantityField.furtherInfo = null;
			},
			clearInputFields() {
				this.barcodeField.value = this.barcodeField.defaultValue;
				this.tagField.value = this.tagField.defaultValue;
			},
			async onReadedBarcode(barcode,type) {
				this.barcodeField.value = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.tagField.value = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			selectedProject(value) {
				this.clearAllFields();
				this.projectField.value = value;
				this.searchProjectRows();
				this.searchPicksAndItems();
				this.$refs.tag.$refs.input.$el.focus()
			},
			unselectedProject() {
				this.clearAllFields();
				this.tablesData.projectRows = [];
				this.tablesData.picks = [];
			},
			async refreshedProject() {
				this.customSearchProjects();
			},
			async assignLocation() {
				if (this.tablesData.location) {
					await this.searchLocation(this.tablesData.location);
				}
			},
			selectedLocation(value) {
				this.locationField.value = value;
				this.tablesData.location = value.id;
				this.$refs.barcode.$refs.input.$el.focus()
			},
			unselectedLocation() {
				this.locationField.value = this.locationField.defaultValue;
			},
			async refreshedLocation() {
				this.getLocations();
			},
			changedBarcode(value) {
				this.barcodeField.value = value;
			},
			async committedBarcode() {
				await this.getFromBarcodeField();
			},
			async getFromBarcodeField() {
				let barcodeElements = this.barcodeField.value.split('|');
				let foundOrder = null;
				let foundItem = null;
				let success = true;
				barcodeElements.forEach(element => {
					switch (true) {
						case element.startsWith(this.barcodeAiProject):
							foundOrder = true;
							this.projectField.value = this.projectField.defaultValue;
							this.tablesData.projects.forEach(project => {
								if (project.name == element.substring(this.barcodeAiProject.length)) {
									this.projectField.value = project;
								}
							});
							break;
						case element.startsWith(this.barcodeAiItem):
							this.itemField.value = this.itemField.defaultValue;
							foundItem = element.substring(this.barcodeAiItem.length);
							break;
						case element.startsWith(this.barcodeAiSerial):
							this.serialField.value = element.substring(this.barcodeAiSerial.length);
							break;
						case element.startsWith(this.barcodeAiQuantity):
							this.quantityField.value = element.substring(this.barcodeAiQuantity.length);
							break;
					}
				});
				if (foundOrder) {
					success = await this.checkOrder();
				}
				else {
					success = false;
					this.showMsgWarning(this.messageTitleCheckBarcode,this.warnBarcodeHasNotOrder);
				}
				if (success && foundItem) success = await this.checkItem(foundItem)
				if (success) this.$refs.tag.$refs.input.$el.focus()
			},
			selectedItem(value) {
				this.itemField.value = value;
				this.descriptionField.value = value.description;
				this.tablesData.picks.forEach(element => {
					if (element.item == value) {
						this.quantityField.furtherInfo = '/ ' + (element.quantity - element.quantity_detected);
					}
				});
				this.$refs.lot.$refs.input.$el.focus()
			},
			unselectedItem() {
				this.itemField.value = this.itemField.defaultValue;
				this.descriptionField.value = this.descriptionField.defaultValue;
			},
			async refreshedItem() {
				this.searchPicksAndItems();
			},
			changedDescription(value) {
				this.descriptionField.value = value;
			},
			changedSerial(value) {
				this.serialField.value = value;
			},
			committedSerial() {
				this.$refs.weight.$refs.input.$el.focus()
			},
			changedQuantity(value) {
				this.quantityField.value = value;
			},
			committedQuantity() {
				this.$refs.tag.$refs.input.$el.focus()
			},
			changedTag(value) {
				this.tagField.value = value;
			},
			async committedTag() {
				let tagElements = this.tagField.value.split('|');
				let success = false;

				switch (tagElements.length) {
					case 0:
						this.tagField.value = this.tagField.defaultValue;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						await this.searchTag()
						if (this.tablesData.tag == null) {
							success = true;
						}
						else {
							this.showMsgWarning(this.messageTitleSave,'TAG ' + this.tablesData.tag + this.warnTagExist);
						}
						break;
					default:
						this.tagField.value = this.tagField.defaultValue;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
				if (success) this.$refs.confirm.$refs.button.$el.focus()
			},
			async exitIncoming() {
				let canExit = !this.isOpenModal;
				
				if (canExit == true) {
					this.$router.push("/home");
				}
			}
		}
	});
</script>
