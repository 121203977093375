<template>
 <ion-page>
		<spot-header :title=getPageTitle :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitProject"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<!--<ion-item>-->
				<!--<ion-accordion-group>-->
					<!--<ion-accordion value="first">-->
						<ion-item slot="header" color="light">
							<spot-input
								:ref="fields[field.search].name"
								:label="fields[field.search].text"
								:allignLabel="fields[field.search].allignLabel"
								:required="fields[field.search].required"
								:placeholder="fields[field.search].placeholder"
								:enabled="true"
								:value="fields[field.search].value"
								:type="fields[field.search].inputType"
								:clearInput="true"
								@textChanged="changedSearch">
							</spot-input>
							<!--
							<ion-button size="default" slot="end" disabled="true" @click="createNewPick">
								<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
								{{ newItemText }}
							</ion-button>
							-->
							<!--<ion-label>First Accordion</ion-label>-->
						</ion-item>
						<!--
						<ion-segment slot="content" class="no-pad" value="viewAll" @ionChange="filterChanged($event)">
							<ion-segment-button class="all-recs no-pad" value="viewAll">{{	allRecsText }}</ion-segment-button>
							<ion-segment-button class="not-readed-recs no-pad" value="viewNotReaded">{{	unreadedRecsText }}</ion-segment-button>
							<ion-segment-button class="not-finished-recs no-pad" value="viewNotFinished">{{	unfinishedRecsText }}</ion-segment-button>
						</ion-segment>
						-->
					<!--</ion-accordion>-->
				<!--</ion-accordion-group>-->
				<!--</ion-item>-->
				<ion-progress-bar type="indeterminate" v-if="!tablesData.picks"></ion-progress-bar> <!-- v-if="!tablesData.projectRows" -->
				<EasyDataTable
					:headers="headers"
					:items="tablesData.picks"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					:body-row-class-name="bodyRowClassNameFunction"
					:rows-items="[10, 25, 50, 100, 200, 500]"
					:rows-per-page="100"
					@click-row="supervisorCreateNewProjectRow"
					multi-sort> <!-- :items="tablesData.projectRows" :body-item-class-name="bodyItemClassNameFunction" show-index alternating -->
					<!--
					<template #item-actions="pick">
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingPick(pick)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingPick(pick)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingPick(pick)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					<template #item-actions="projectRow">
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProjectRow(projectRow)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingProjectRow(projectRow)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingProjectRow(projectRow)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					-->
					<template #expand="row">
						{{row.item.description}}
					</template>
				</EasyDataTable>
				<ion-modal class="modal-settings" ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div v-for="field in itemContents" :key="field.id">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:furtherInfo="field.furtherInfo"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
							<ion-textarea
								v-if="field.inputType == 'area'"
								:ref="field.name"
								:placeholder="getPlaceHolder(field)"
								:required="field.required"
								:disabled="!field.enabled || modalReadOnly"
								:auto-grow="true"
								:value="field.value">
							</ion-textarea>
							<spot-checkbox
								v-if="field.inputType == 'check'"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:enabled="field.enabled && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								@checkboxChanged="changedCheckboxField(field.id, $event)"
								@focus="$event.target.select()">
							</spot-checkbox>
							<spot-select
								v-if="field.inputType == 'select'"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled && !modalReadOnly && isSupervisor"
								:enabledExtraButtons="field.enabledExtraButtons && !modalReadOnly"
								:validationState="field.validationState"
								:value="field.value"
								:options="tablesData.packages"
								:optionField="field.refField"
								:canAddItem="field.canAddItem"
								:canPrint="field.canPrint && this.tablesData.packages.length > 0 && !!this.tablesData.currentPackage"
								:canRefreshList="field.canRefresh"
								:canUnlock="field.canUnlock"
								:selectedLabel="selectedText"
								:deselectLabel="deselectText"
								:selectLabel="selectText"
								@selected="assignedSelectField(field.id, $event)"
								@unselected="resetSelectField(field.id)"
								@addItem="addPackageAndPrintLabel"
								@print="reprintLabel">
							</spot-select>
						</div>
						<ion-label color="tertiary" v-if="isActingDb">{{isWaitingActingDbText}}</ion-label>
						<div class="flex-row-center-container">
							<!--
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
							-->
							<ion-button fill="outline" size="large" color="primary" :disabled="false" @click="closeModal(false)">
								<ion-spinner v-if="isActingDb"></ion-spinner>
								<span v-else>{{ undoText }}</span>
							</ion-button>
							<ion-button fill="outline" size="large" color="primary" :disabled="isEmptyRequiredFields == true || modalReadOnly == true || isActingDb == true" @click="closeModal(true)">
								<ion-spinner v-if="isActingDb"></ion-spinner>
								<span v-else>{{ confirmText }}</span>
							</ion-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('C0020010112','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

 </ion-page>
</template>

<style scoped>
	.modal-settings {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	ion-textarea {
		font-size: 12px;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.all-recs {
		--color-checked: var(--ion-color-dark);
		--background-checked: var(--ion-color-success);
	}
	.not-readed-recs {
		--color-checked: var(--ion-color-dark);
		--background-checked: var(--ion-color-danger);
	}
	.not-finished-recs {
		--color-checked: var(--ion-color-dark);
		--background-checked: var(--ion-color-warning);
	}
</style>
<style>
	.empty-row  {
		--easy-table-body-row-background-color: var(--ion-color-danger); /*#f56c6c;*/
	}
	.partial-row  {
		--easy-table-body-row-background-color: var(--ion-color-warning); /*#ddc229;*/
	}
	.completed-row  {
		--easy-table-body-row-background-color: var(--ion-color-success); /*#67c23a;*/
	}
	.exceeded-row  {
		--easy-table-body-row-background-color: var(--ion-color-tertiary);/*#0d9de0;*/
	}
	.prominence-column {
		font-size: 20px;
	}
</style>

<script>
import { /*IonButton, */IonContent, /*IonIcon, */IonItem, IonModal, IonPage, IonProgressBar, IonTextarea, IonTitle, IonToolbar, /*IonAccordion, IonAccordionGroup, IonSegment, IonSegmentButton*/ } from '@ionic/vue';
import { brush, duplicate, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotSelect from '../components/SpotSelect.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { checkUserSession, confirmYesNo, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { getPrinter, createPackage, createProjectRow, deleteProjectRow, deletePick, executePrinterAction, getPick, getProject, jsonSearchPicks, searchPackages, searchPicksWithDocuments, searchPrinter, searchProjectRows, tablesData, updateDocument, updatePick, updateProjectRow } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "ManageProject",
		props: {
			pageTitle: { type: String, default: 'Gestisci Progetto' },
			manageText: { type: String, default: 'Gestisci ' }, //Articolo Padre: 
			manageReferenceItemText: { type: String, default: 'Gestisci Articolo: ' }, //Articolo Padre: 
			noItemsText: { type: String, default: 'Nessun Articolo presente' },
			newItemText: { type: String, default: 'Nuovo Articolo' },
			editItemText: { type: String, default: 'Modifica Articolo'}, //'Modifica Articolo: '
			showItemText: { type: String, default: 'Visualizza Articolo'}, //'Visualizza Articolo: '
			isWaitingActingDbText: { type: String, default: 'Aggiornamento liste in corso... Attendere'},
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			allRecsText: { type: String, default: 'tutti' },
			unreadedRecsText: { type: String, default: 'non letti' },
			unfinishedRecsText: { type: String, default: 'non completi' },
			supervisorRoleText: { type: String, default: 'supervisore' },
			messageSearchItemTitle: { type: String, default: 'Ricerca Articolo' },
			messageSearchItemNotFound: { type: String, default: 'Articolo non trovato nel documento corrente!' },
			messageSearchPackageTitle: { type: String, default: 'Ricerca Collo' },
			messageSearchPackageNotFound: { type: String, default: 'Collo non trovato!' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'articolo corrente?' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			messageTitleInsert: { type: String, default: "Inserimento Nuovo Collo" },
			successAddPackageMessage: { type: String, default: "Nuovo collo inserito a sistema." },
			confirmExitTitle: { type: String, default: 'Conferma Uscita' },
			confirmExitMessage: { type: String, default: 'Confermi l\'uscita dalla rilevazione anche se non sono stati inseriti tutti i dati?' },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			messageTitleReprint: { type: String, default: "Ristampa Etichetta" },
			messageTitleInvalidQuantity: { type: String, default: "Quantità non valida" },
			warnInavlidQuantity: { type: String, default: 'La Quantità inserita sommata alla Quantità rilevata in precedenza è superiore alla Quantità Prevista!' },
			//successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichetta: " },
			successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichette stampate: " },
			successReprintMessage: { type: String, default: "Ristampa etichetta avvenuta correttamente. Etichetta stampata: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa dell'etichetta" },
			// errorPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se la stampa non è avvenuta (l'etichetta non potrà essere stampata successivamente)?" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningUnassignedPrinterConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
			warningNotFoundPrinterConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se la stampante è spenta o non raggiungibile (l'etichetta non verrà stampata)?" },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			// IonButton,
			// IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			IonTextarea,
			// IonAccordionGroup,
			// IonAccordion,
			// IonSegment,
			// IonSegmentButton,
			SpotHeader,
			SpotInput,
			SpotCheckbox,
			SpotSelect,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Articolo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['item.code','item.description'],
			filters: {
				allRecs: true,
				nullOrZeroQuantity: null,
				partialQuantity: null,
			},
			headers: [
				// { text: 'Ubicazione', value: 'location.name', sortable: true },
				{ text: 'Articolo', value: 'item.code', sortable: true },
				{ text: 'UM', value: 'item.measure_unit.name', sortable: false },
				{ text: 'Previsti', value: 'quantity', sortable: false },
				{ text: 'Rilevati', value: 'quantity_detected', sortable: false },
				// { text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'asc'],
			sortBy: ['location.name','item.code'],
			itemContent: {
				code: 0,
				description: 1,
				expectedQuantity: 2,
				quantity: 3,
				package: 4,
			},
			itemContents: [
				{ id: 0, name: 'code', text: 'Articolo', enabled: false, placeholder: 'Inserisci Codice Articolo', inputType: 'text', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canPrint: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'description', text: 'Descrizione', enabled: false, placeholder: 'Inserisci Descrizione', inputType: 'area', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canPrint: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'expectedQuantity', enabled: false, text: 'Quantità Prevista', placeholder: 'Inserisci Quantità Prevista', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canPrint: false, canRefresh: false, canUnlock: false },
				{ id: 3, name: 'quantity', text: 'Quantità', enabled: true, placeholder: 'Inserisci Quantità', inputType: 'number',  defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canPrint: false, canRefresh: false, canUnlock: false },
				{ id: 4, name: 'package', text: 'Cod. Pacco', enabled: true, enabledExtraButtons: true, placeholder: 'Inserisci Cod. Pacco', inputType: 'select', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: true, canPrint: true, canRefresh: false, canUnlock: false },
			],
			isOpenModal: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			isRevision: null,
			isLocked: null,
			projectId: null,
			documentId: null,
			printerId: null,
			lastBarcode: null,
			lastTag: null,
			assignRemaingQuantity: false,
			allowExceededQuantity: false,
			isSupervisor: false,
			isActingDb: false,
			duplicate,
			trash,
			brush,
			checkUserSession,
			confirmYesNo,
			settings,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			getPick,
			getPrinter,
			getProject,
			searchPicksWithDocuments,
			searchProjectRows,
			searchPackages,
			searchPrinter,
			jsonSearchPicks,
			updatePick,
			createProjectRow,
			createPackage,
			updateProjectRow,
			deletePick,
			deleteProjectRow,
			updateDocument,
			executePrinterAction,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isActive = false;
		},
		computed: {
			getPageTitle() {
				let newTitle = null; // this.pageTitle;
				if (this.tablesData) {
					newTitle = ''; // this.manageText;
					newTitle += (!this.tablesData.currentDocument ? '' : this.tablesData.currentDocument.name);
					newTitle += (!this.tablesData.currentReferenceItem ? '' : ' | ' + this.tablesData.currentReferenceItem.name);
				}
				else {
					newTitle = this.manageReferenceItemText;
				}
				return newTitle;
			},
			isEmptyRequiredFields() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
			isOrderClean() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity_detected > 0) ret=false});
				return ret;
			},
			isOrderFinished() {
				let ret = true;
				if (this.allowExceededQuantity == true) this.tablesData.picks.forEach(element => {if (element.quantity < element.quantity_detected) ret=false});
				else this.tablesData.picks.forEach(element => {if (element.quantity != element.quantity_detected) ret=false});				
				return ret;
			},
			canPrint() {
				return (this.settings.printerManagement && (this.printerId != null))
			},
		},
		setup() {
			const bodyRowClassNameFunction = (item) => {
				if (parseFloat(item.quantity_detected) == parseFloat(item.quantity)) return 'completed-row'
				else if(parseFloat(item.quantity_detected) == parseFloat(0)) return 'empty-row'
				else if(parseFloat(item.quantity_detected) < parseFloat(item.quantity)) return 'partial-row'
				else return 'exceeded-row';
			}
			const bodyItemClassNameFunction = (column) => {
				if (column === 'quantity' || column === 'quantity_detected') return 'prominence-column';
				else return '';
			};
			return {
				bodyRowClassNameFunction,
				bodyItemClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (/true/i).test(this.$route.query.incoming);
				this.isOutgoing = (/true/i).test(this.$route.query.outgoing);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.isRevision = (/true/i).test(this.$route.query.revision);
				this.isLocked = (/true/i).test(this.$route.query.locked);
				this.documentId = parseInt(this.$route.query.document);
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
				this.isSupervisor = this.checkRole();
				this.isActingDb = false
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData(checkPrinter = true) {
				await this.getProject(this.projectId);
				this.tablesData.projectRows = [];
				this.tablesData.picks = [];
				// await this.searchProjectRows(this.projectId);
				// await this.searchPicksWithDocuments(this.projectId, this.documentId);
				await this.customSearchPicks();
				this.tablesData.packages = [];
				await this.searchPackages(this.projectId, false, false);
				this.tablesData.currentPackage = null;
				if (this.settings.printerManagement == true && checkPrinter == true) {
					this.tablesData.printers = [];
					this.tablesData.currentPrinter = null;
					await this.searchPrinter(true);
					if (!this.tablesData.currentPrinter) {
						this.printerId = null;
						this.confirmYesNo(this.messageTitlePrint, this.warningUnassignedPrinterConfirmMessage)
						.then((data) => {
							if (data == false) {
								this.$router.push("/project/selection" + "?incoming=" + this.isIncoming + "&outgoing=" + this.isOutgoing + "&picking=" + this.isPicking + "&keepProject=" + true)
							}
						})
					}
					else {
						this.printerId = this.tablesData.currentPrinter.id;
						if (!this.tablesData.currentPrinter.is_online) {
							this.confirmYesNo(this.messageTitlePrint, this.warningNotFoundPrinterConfirmMessage)
							.then((data) => {
								if (data == false) {
									this.$router.push("/project/selection" + "?incoming=" + this.isIncoming + "&outgoing=" + this.isOutgoing + "&picking=" + this.isPicking + "&keepProject=" + true)
								}
							})
						}
					}
				}
			},
			async deleteExistingPick(pick) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deletePick(pick.id)
				}
			},
			async deleteExistingProjectRow(projectRow) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteProjectRow(projectRow.id)
				}
			},
			async customSearchPicks() {
				this.tablesData.picks = [];
				let mySearch = {
					"q":{
						"project_id_eq": this.projectId,
					},
				};
				!this.documentId ? mySearch.q['document_id_null'] = true  : mySearch.q['document_id_eq'] = this.documentId;
				if (this.filters.partialQuantity == true) mySearch.q['quantity_remaining_ne'] = parseFloat(0);
				if (this.filters.quantityNullOrZero == true) mySearch.q['quantity_detected_eq'] = parseFloat(0);
				await this.jsonSearchPicks(mySearch);
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			assignedSelectField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			resetSelectField(fieldId) {
				this.itemContents[fieldId].value = null;
				this.itemContents[fieldId].validationState = false
			},
			async filterChanged(event){
				switch (event.detail.value) {
					case 'viewAll':
						this.filters.allRecs = true;
						this.filters.nullOrZeroQuantity = false;
						this.filters.partialQuantity = false;
						break;
					case 'viewNotReaded':
						this.filters.allRecs = false;
						this.filters.nullOrZeroQuantity = true;
						this.filters.partialQuantity = false;
						break;
					case 'viewNotFinished':
						this.filters.allRecs = false;
						this.filters.nullOrZeroQuantity = false;
						this.filters.partialQuantity = true;
						break;
				}
				await	this.customSearchPicks();
			},
			async addPackage() {
				this.tablesData.currentPackage = null;
				await this.createPackage(this.prepareNewPackage(), false);
				await this.searchPackages(this.projectId, false, false);
				if (this.tablesData.currentPackage != null) {
					this.itemContents[this.itemContent.package].value = this.tablesData.currentPackage;
					this.itemContents[this.itemContent.package].validationState = true;
					return true;
				}
				else {
					return false;
				}
			},
			prepareNewProjectRow() {
				let myRecord = {
					project_rows: {
						project_id: this.tablesData.currentPick.project_id, // this.projectId
						pick_id: this.tablesData.currentPick.id,
						document_id: this.tablesData.currentPick.document_id, //this.documentId
						location_id: this.tablesData.currentPick.location.id,
						item_id: this.tablesData.currentPick.item.id,
						quantity: this.itemContents[this.itemContent.quantity].value,
						external_reference: this.tablesData.currentPick.external_code,
						package_id: this.itemContents[this.itemContent.package].value.id,
						user_id: this.tablesData.currentUser.id,
					}
				};
				return myRecord;
			},
			prepareNewPackage() {
				let myRecord = {
					packages: {
						project_id: this.projectId,
						closed: false,
						shipped: false,
					}
				};
				return myRecord;
			},
			prepareUpdatePick() {
				// autoclose
				/*
				let newQty = parseFloat(this.tablesData.currentPick.quantity_detected) + parseFloat(this.itemContents[this.itemContent.quantity].value);
				let expectedQty = parseFloat(this.tablesData.currentPick.quantity);
				*/
				let myRecord = {
					picks: {
						lock_version: this.tablesData.currentPick.lock_version,
						user_id: this.tablesData.currentUser.id,
						// autoclose
						/*
						closed: (!this.allowExceededQuantity)? newQty >= expectedQty : newQty == expectedQty,
						*/
					}
				};
				return myRecord;
			},
			prepareUpdateProjectRow() {
				let myRecord = {
					project_rows: {
						quantity: this.itemContents[this.itemContent.quantity].value,
						lock_version: this.tablesData.currentProjectRow.lock_version,
						user_id: this.tablesData.currentUser.id,
					}
				};
				return myRecord;
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			createCustomItemContents(pickRow) {
				this.itemContents[this.itemContent.code].value = pickRow.item.code
				this.itemContents[this.itemContent.description].value = pickRow.item.description
				this.itemContents[this.itemContent.expectedQuantity].value = pickRow.quantity
				this.itemContents[this.itemContent.expectedQuantity].furtherInfo = (pickRow.quantity_detected > 0)? ' (già inseriti: ' + parseFloat(pickRow.quantity_detected) + ')': null
				//this.itemContents[this.itemContent.quantity].value = (!this.assignRemaingQuantity)? null : (parseFloat(pickRow.quantity) - parseFloat(pickRow.quantity_detected))
				//this.itemContents[this.itemContent.quantity].value = (!this.assignRemaingQuantity)? ((parseFloat(pickRow.quantity) == parseFloat(1) && parseFloat(pickRow.quantity_detected) == parseFloat(0))? parseFloat(1) : null) : (parseFloat(pickRow.quantity) - parseFloat(pickRow.quantity_detected))
				//this.itemContents[this.itemContent.quantity].value = (!this.assignRemaingQuantity)? ((parseFloat(pickRow.quantity_remaining) == parseFloat(1))? parseFloat(1) : null) : parseFloat(pickRow.quantity_remaining)
				this.itemContents[this.itemContent.quantity].value = (!this.assignRemaingQuantity)? parseFloat(1) : parseFloat(pickRow.quantity_remaining)

				//this.itemContents[this.itemContent.quantity].furtherInfo = ' / ' + (parseFloat(pickRow.quantity) - parseFloat(pickRow.quantity_detected))
				this.itemContents[this.itemContent.package].value = null
			},
			updateCustomItemContents(row) {
				this.itemContents[this.itemContent.id].value = row.project_row.id
				this.itemContents[this.itemContent.location].value = row.location.name
				this.itemContents[this.itemContent.code].value = row.item.code
				this.itemContents[this.itemContent.description].value = row.item.description
				this.itemContents[this.itemContent.expectedQuantity].value = parseInt(row.quantity)
				this.itemContents[this.itemContent.quantity].value = parseInt(row.quantity_detected)
			},
			createNewPick() {
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async supervisorCreateNewProjectRow(tableRow) {
				if (this.isSupervisor == true) await this.createNewProjectRow(tableRow);
			},
			async createNewProjectRow(tableRow) {
				if (!tableRow) {
					this.itemContents.forEach(element => element.value = element.defaultValue);
				}
				else {
					this.tablesData.currentPick = tableRow;
					await this.getPick(tableRow.id);
					this.createCustomItemContents(this.tablesData.currentPick);
				}
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async editExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async editExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async showExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			checkRole() {
				let ret = false;
				this.tablesData.currentUser.roles.forEach(element => {if (element.name.toLowerCase() == this.supervisorRoleText.toLowerCase()) ret=true});
				return ret;
			},
			checkQuantity() {
				let ret = true;
				if (!this.allowExceededQuantity) {
     if ((parseFloat(this.tablesData.currentPick.quantity_detected) + parseFloat(this.itemContents[this.itemContent.quantity].value)) > parseFloat(this.tablesData.currentPick.quantity)) {
						this.showMsgWarning(this.messageTitleInvalidQuantity, this.warnInavlidQuantity);
						ret = false;
					}
				}
				return ret;
			},
			async closeModal(saveRecord) {
				let canCloseModal = true;
				this.isActingDb = true;
				if (saveRecord == true) {
					if (this.checkQuantity() == true) {
						if (this.actionDbCreate == true) {
							let updatePickState = null;
							updatePickState = await this.updatePick(this.tablesData.currentPick.id, this.prepareUpdatePick(), false);
							if (updatePickState == true) {
								await this.createProjectRow(this.prepareNewProjectRow(), false)
							}
						}
						else {
							await this.updateProjectRow(this.itemContents[this.itemContent.id].value, this.prepareUpdateProjectRow(), false)
						}
					}
					else {
						canCloseModal = false;
					}
				}
				if (canCloseModal) {
					await this.getAllTablesData(false);
					this.isOpenModal = false;
				}
				this.isActingDb = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				if (this.isOpenModal == false) {
					this.tablesData.picks.some(element => {
						if (element.item.code == this.lastBarcode) {
							foundElement = element;
							return true;
						}
					});
					if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound);
					else await this.createNewProjectRow(foundElement);
				}
				else if (this.itemContents[this.itemContent.code].value == this.lastBarcode) {
					this.itemContents[this.itemContent.quantity].value = (!this.itemContents[this.itemContent.quantity].value)? parseFloat(1) : parseFloat(this.itemContents[this.itemContent.quantity].value) + 1;
				}
				else {
					this.tablesData.packages.some(element => {
						if (element.name == this.lastBarcode) {
							foundElement = element;
							return true;
						}
					});
					if (!foundElement) this.showMsgWarning(this.messageSearchPackageTitle, this.messageSearchPackageNotFound);
					else this.assignedSelectField(this.itemContents[this.itemContent.package].id, foundElement);
				}
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.picks.some(pick => {
							if (pick.item.code == this.lastTag) {
								foundElement = pick;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchItemTitle, this.messageSearchItemNotFound);
						else this.editExistingPick(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			async addPackageAndPrintLabel() {
				let addPackageState = await this.addPackage();
				if (addPackageState && this.canPrint) {
					await this.getPrinter(this.printerId) //this.printerId
					if (this.tablesData.currentPrinter.is_online == true) {
						await this.executePrinterAction(`printers/` + this.printerId + `?do=print_single_barcode` + `&barcode=` + this.tablesData.currentPackage.name)
						.then(() => {
							this.showMsgInfo(
								this.messageTitleInsert,
								this.successAddPackageMessage + "<br><br>" + this.successPrintMessage + "&nbsp" + "1");
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgWarning(
								this.messageTitlePrint,
								this.warningPrinterOffline);
					}
				}
				else {
					this.showMsgInfo(
						this.messageTitleInsert,
						this.successAddPackageMessage);
				}
			},
			async reprintLabel() {
				if (!!this.tablesData.currentPackage && this.canPrint) {
					await this.getPrinter(this.printerId) //this.printerId
					if (this.tablesData.currentPrinter.is_online == true) {
						await this.executePrinterAction(`printers/` + this.printerId + `?do=print_single_barcode` + `&barcode=` + this.tablesData.currentPackage.name)
						.then(() => { this.showMsgInfo(this.messageTitleReprint, this.successReprintMessage + "&nbsp" + this.tablesData.currentPackage.name); })
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitleReprint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgWarning(this.messageTitleReprint,this.warningPrinterOffline);
					}
				}
			},
			async exitProject() {
				let canExit = true;
				let myRecord = {
					document: {}
				};
				
				if (this.isOrderFinished == true) {
					if (this.isIncoming == true) myRecord.document['closed'] = true;
					if (this.isOutgoing == true) myRecord.document['delivered'] = true;
					// without autoclose
					if (this.isPicking == false) await this.updateDocument(this.documentId, myRecord, false);
					// with autoclose
					/*
					if (this.isPicking == true) myRecord.document['closed'] = true;
					await this.updateDocument(this.documentId, myRecord, false);
					*/
				}
				else {
					if (this.isOrderClean != true) {
						myRecord.document['partial'] = true;
						// without autoclose
						if (this.isPicking == false) await this.updateDocument(this.documentId, myRecord, false);
						// with autoclose
						// await this.updateDocument(this.documentId, myRecord, false);
						await this.confirmYesNo(this.confirmExitTitle, this.confirmExitMessage)
						.then((data) => { canExit = data })
					} else {
						myRecord.document['closed'] = false;
						myRecord.document['delivered'] = false;
						myRecord.document['partial'] = false;
						// without autoclose
						if (this.isPicking == false) await this.updateDocument(this.documentId, myRecord, false);
						// with autoclose
						// await this.updateDocument(this.documentId, myRecord, false);
					}
				}

				if (canExit == true) {
					this.$router.push("/project/selection" + "?incoming=" + this.isIncoming + "&outgoing=" + this.isOutgoing + "&picking=" + this.isPicking + "&keepProject=" + true);
				}
			}
		}
	});
</script>
